// import AboutPage from "./components/Pages/AboutPage";
// import BlogPage from "./components/Pages/BlogPage";
// import BlogDetailsPage from "./components/Pages/BlogDetailsPage";
// import ContactPage from "./components/Pages/ContactPage";

// import PortfolioDetailsPage from "./components/Pages/PortfolioDetailsPage";
// import ServiceDetailsPage from "./components/Pages/ServiceDetailsPage";
// import ServicesPage from "./components/Pages/ServicesPage";
// import TeamPage from "./components/Pages/TeamPage";
// import PortfolioPage from "./components/Pages/PortfolioPage";
// import TeamDetails from "./components/Pages/TeamDetails";
// import PhotographyAgencyHome from "./components/Pages/PhotographyAgencyHome";
// import CreativePortfolioHome from "./components/Pages/CreativePortfolioHome";
// import DigitalAgencyHome from "./components/Pages/DigitalAgencyHome";
// import MarketingAgencyHome from "./components/Pages/MarketingAgencyHome";
// import ShowcasePortfolioHome from "./components/Pages/ShowcasePortfolioHome";
// import CaseStudyShowcaseHome from "./components/Pages/CaseStudyShowcaseHome";
// import CaseStudyDetailsPage from "./components/Pages/CaseStudyDetailsPage";
// import FaqPage from "./components/Pages/FaqPage";
// import ThanksPage from "./components/Pages/ThanksPage";
import { Route, Routes } from "react-router-dom";
import ErrorPage from "./components/Pages/ErrorPage";
import Home from "./components/Pages/Home";
import AbfExpo from "./components/Pages/AbfExpo";
import FranqueadosParceirosForm from "./components/Pages/FranqueadosParceirosForm";
// import AbfExpo3 from "./components/Pages/AbfExpo3";
import Eventos from "./components/Pages/Eventos";
import PG06RD from "./components/Pages/PG06RD";

// import Obrigado from "./components/Pages/Obrigado";
import Videos from "./components/Pages/Videos";
import Layout from "./components/Layout";
import Layout2 from "./components/Layout/Layout2";
import Layout3 from "./components/Layout/Layout3";
import ScrollToTop from "./components/ScrollToTop";

function App() {
  return (
    <>
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Home />} />

          <Route path="abf-expo" index element={<AbfExpo />} />
          {/* <Route path="abf-expo3" index element={<AbfExpo3 />} /> */}
          <Route path="evento" element={<Eventos />} />
          {/* <Route path="pg-06-rd" element={<PG06RD />} /> */}
          {/* <Route
            path="photography-agency"
            element={<PhotographyAgencyHome />}
          />
          <Route path="digital-agency" element={<DigitalAgencyHome />} />
          <Route path="marketing-agency" element={<MarketingAgencyHome />} />
          <Route path="about" element={<AboutPage />} />
          <Route path="ob-pg-01" element={<ThanksPage />} />
          <Route path="service" element={<ServicesPage />} />
          <Route
            path="service/:serviceDetailsId"
            element={<ServiceDetailsPage />}
          />
          <Route path="portfolio" element={<PortfolioPage />} />
          <Route
            path="portfolio/:portfolioDetailsId"
            element={<PortfolioDetailsPage />}
          />
          <Route path="blog" element={<BlogPage />} />
          <Route path="blog/:blogDetailsId" element={<BlogDetailsPage />} />
          <Route path="contact" element={<ContactPage />} />
          <Route path="team" element={<TeamPage />} />
          <Route path="team/:teamDetails" element={<TeamDetails />} />
          <Route
            path="/case-study/:caseStudyDetailsId"
            element={<CaseStudyDetailsPage />}
          />
          <Route path="faq" element={<FaqPage />} /> */}
        </Route>
        {/* <Route
          path="/"
          element={<Layout headerVariant="cs-site_header_full_width" />}
        >
          <Route
            path="creative-portfolio"
            element={<CreativePortfolioHome />}
          />
          <Route
            path="showcase-portfolio"
            element={<ShowcasePortfolioHome />}
          />
          <Route
            path="case-study-showcase"
            element={<CaseStudyShowcaseHome />}
          />
        </Route> */}
        <Route path="*" element={<ErrorPage />} />
        <Route element={<Layout2 />}>
          {/* <Route path="obrigado" element={<Obrigado />} /> */}
          <Route path="videos-historia" element={<Videos />} />
        </Route>
        <Route element={<Layout3 />}>
          <Route
            path="franqueados-parceiros"
            element={<FranqueadosParceirosForm />}
          />
        </Route>
      </Routes>
    </>
  );
}

export default App;
