import "vidstack/styles/defaults.css";
import "vidstack/styles/community-skin/video.css";

import {
  MediaCommunitySkin,
  MediaOutlet,
  MediaPlayer,
  MediaPoster,
} from "@vidstack/react";

export default function VideoPlayer({ src, poster }) {
  return (
    <MediaPlayer
      src={src}
      poster={poster}
      aspectRatio={16 / 9}
      className="yt-lite"
    >
      <MediaOutlet>
        <MediaPoster />
      </MediaOutlet>
      <MediaCommunitySkin />
    </MediaPlayer>
  );
}
