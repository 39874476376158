import React, { useEffect } from "react";
import FunFact from "../FunFact";
import Hero from "../Hero";
import Div from "../Div";
import SectionHeading from "../SectionHeading";
import Spacing from "../Spacing";
import Cta from "../Cta";
import MovingText from "../MovingText";
import SuporteSlider from "../Slider/SuporteSlider";
import VideoModal from "../VideoModal";
import { pageTitle } from "../../helper";
import ButtonUI from "../Button";
import SpaceSlider from "../Slider/SpaceSlider";
import NewsSlider from "../Slider/NewsSlider";
import FunFact2 from "../FunFact/FunFact2";
import MetaTags from "react-meta-tags";
import dadosFranquia from "../../vendors/dadosFranquia.json";
import dadosUnidades from "../../vendors/dadosUnidades.json";
import dadosFunfaceRetorno from "../../vendors/dadosFunfactRetorno.json";

import "vidstack/styles/defaults.css";
import "vidstack/styles/community-skin/video.css";

import {
  MediaCommunitySkin,
  MediaOutlet,
  MediaPlayer,
  MediaPoster,
} from "@vidstack/react";

export default function AbfExpo() {
  pageTitle("ABF Expo");

  function VideoPlayer({ src, poster }) {
    return (
      <MediaPlayer
        src={src}
        poster={poster}
        aspectRatio={16 / 9}
        className="yt-lite"
      >
        <MediaOutlet>
          <MediaPoster />
        </MediaOutlet>
        <MediaCommunitySkin />
      </MediaPlayer>
    );
  }

  const buttonLink = "https://form.typeform.com/to/pwbLhdYC";

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <MetaTags>
        <meta name="author" content="Cheirin Bão" />
        <meta http-equiv="content-language" content="pt-br" />
        <meta name="robots" content="index,nofollow" />
        <meta
          property="og:url"
          content="https://lp.universalfranchising.com.br/"
        />
        <meta
          name="copyright"
          content="Copyright © 2023 | UF Gestão de Marcas e Patentes LTDA | CNPJ: 32.071.571/0001-03"
        />
        <meta
          name="description"
          content="Invista em uma marca com história, credibilidade, solidez, alta rentabilidade e que soma mais de 800 unidades em todo o Brasil."
        />
        <meta
          name="og:description"
          content="Invista em uma marca com história, credibilidade, solidez, alta rentabilidade e que soma mais de 800 unidades em todo o Brasil."
        />
        <meta
          property="og:title"
          content="Seja franqueado da maior rede de cafeterias do Brasil!"
        />
        <meta property="og:image" content="/images/logos/logo.png" />
        <meta property="og:locale" content="pt_BR" />
        <meta property="og:site_name" content="Empório Mineiro Cheirin Bão" />
      </MetaTags>
      {/* Start Hero Section */}
      {/* Start Hero Section */}
      <Hero
        title="Seja franqueado da maior rede de cafeterias do Brasil!"
        subtitle="Invista em uma marca com história, credibilidade, solidez, alta rentabilidade e que soma mais de 800 unidades em todo o Brasil."
        btnText="Cadastre-se para mais informações"
        btnLink={buttonLink}
        btnVariant="contained"
        scrollDownId="#next"
        bgImageUrl="/images/bg/bg-cb-1.jpg"
        backgroundColor="#ffab00"
        btnTextColor="#000"
        btnTextHoverColor="#ffab00"
        backgroundHoverColor="#000"
      />
      {/* End Hero Section */}

      {/* Start FunFact Section */}
      <div className="container" id="next">
        <FunFact
          variant="cs-type1"
          title="Presença nacional em todos os Estados Brasileiros!"
          subtitle=" Com a Cheirin Bão você empreende em um modelo de negócio
          validando e uma marca consolidada no mercado de norte ao sul do
          país!"
          data={dadosFranquia.data}
        />
      </div>
      {/* End FunFact Section */}

      {/* <Spacing lg="145" md="80" /> */}
      {/* <Div className="container">
        <Div className="row">
          <Div className="col-xl-5 col-lg-6 div-center">
            <Div id="white" className="cs-image_layer cs-style1">
              <Div className="cs-image_layer_in">
                <img loading="lazy"
                  src="/images/unidades/mapa.png"
                  alt="Mapa"
                  className="w-100 cs-radius_15 "
                />
              </Div>
            </Div>
          </Div>
          <Div className="col-xl-6 offset-xl-1 col-lg-6 div-center ">
            <SectionHeading
              title="Presença nacional em todos os Estados Brasileiros!"
              subtitle="Cheirin de um bom negócio!"
            >
              <Div className="cs-separator cs-accent_bg"></Div>
              <Spacing lg="25" md="0" />
              <p className="cs-m0">
                Com a Cheirin Bão você empreende em um modelo de negócio
                validando e uma marca consolidada no mercado de norte ao sul do
                país!
              </p> */}
      {/* <Spacing lg="30" md="30" />
              <ButtonUI
                btnText="Cadastre-se para mais informações"
                backgroundColor="#ffab00"
                btnTextColor="#000"
                btnTextHoverColor="#ffab00"
                backgroundHoverColor="#000"
                variant="contained"
                scrollDownId="#service"
              ></ButtonUI>
              <Spacing lg="25" md="0" /> */}
      {/* </SectionHeading>
          </Div>
        </Div>
      </Div> */}
      {/* End Why Choose Section */}

      {/* End Especialistas Section */}
      <Spacing lg="145" md="80" />
      <Div className="container">
        <Div className="row">
          <Div className="col-xl-6 col-lg-6 div-center ">
            <SectionHeading
              id="faturar"
              title="É possível faturar R$150 mil ao mês com uma franquia da Cheirin Bão?"
              subtitle="Cheirin de um bom negócio!"
            >
              <Div className="cs-separator cs-accent_bg"></Div>
              <Spacing lg="25" md="0" />
              <p className="cs-m0">
                Os fundadores da marca foram questionados se é possível faturar
                R$ 150 Mil ao mês com a Cheirin Bão, confira:
              </p>
            </SectionHeading>
          </Div>
          <Div className="col-xl-5 col-lg-6 offset-xl-1  div-center ">
            <Div className="cs-image_layer cs-style1">
              <Div className="cs-image_layer_in">
                <VideoPlayer
                  src="https://customer-fv29kr9ah5btqwva.cloudflarestream.com/cb7d8fbda23ac9c5514627adec49ebb3/manifest/video.m3u8"
                  poster="https://customer-fv29kr9ah5btqwva.cloudflarestream.com/cb7d8fbda23ac9c5514627adec49ebb3/thumbnails/thumbnail.jpg"
                />
              </Div>
            </Div>
          </Div>
        </Div>
      </Div>
      {/* End Especialistas Section */}

      {/* End Especialistas Section */}
      <Spacing lg="145" md="80" />
      <Div className="container">
        <Div className="row reverse">
          <Div className="col-xl-5 col-lg-6 div-center  ">
            <Div className="cs-image_layer cs-style1">
              <Div className="">
                <VideoPlayer
                  src="https://customer-fv29kr9ah5btqwva.cloudflarestream.com/b1d62f556d8a67b0dd0a5e25cee54f5d/manifest/video.m3u8"
                  poster="https://customer-fv29kr9ah5btqwva.cloudflarestream.com/b1d62f556d8a67b0dd0a5e25cee54f5d/thumbnails/thumbnail.jpg"
                />
              </Div>
            </Div>
          </Div>
          <Div className="col-xl-6 col-lg-6 offset-xl-1 div-center ">
            <SectionHeading
              title="Você conhece o lucro líquido
              que nossos franqueados colocam no bolso?"
              subtitle="Cheirin de um bom negócio!"
            >
              <Div className="cs-separator cs-accent_bg"></Div>
              <Spacing lg="25" md="0" />
              <p className="cs-m0">
                Confira o trecho que a nossa franqueada Aparecida da Cheirin Bão
                do Parque Shopping Sulacap no Rio de Janeiro revelou sobre a sua
                rentabilidade:
              </p>
            </SectionHeading>
          </Div>
        </Div>
      </Div>
      {/* End Especialistas Section */}

      {/* Start FunFact Section */}
      <Spacing lg="150" md="80" />
      <Div className="container">
        <FunFact2
          title="Payback Cheirin Bão"
          subtitle="Você deve estar se perguntando se a Cheirin Bão é um bom negócio, certo? À partir daqui a gente te mostra o porquê de ser um ótimo investimento. No período da pandemia nossa rede teve um crescimento de 258% em vendas de nossos produtos e 171% em novas unidades. *Pode variar de acordo com a gestão do franqueado."
          data={dadosFunfaceRetorno.data}
          variant="cs-no_shadow"
          bgUrl="/images/funfact_shape_bg.svg"
        />
      </Div>
      {/* End FunFact Section */}

      <Spacing lg="150" md="80" />
      <Div className="container">
        <Div className="row reverse">
          <Div className="col-xl-5 col-lg-6 div-center ">
            <Div className="cs-image_layer cs-style1">
              <Div className="cs-image_layer_in">
                <img
                  loading="lazy"
                  src="/images/bg/mio-cafe.jpg"
                  alt="About"
                  className="w-100 cs-radius_15"
                />
              </Div>
            </Div>
          </Div>
          <Div className="col-xl-6 offset-xl-1 col-lg-6 div-center ">
            <SectionHeading
              title="O mió café do mundo!"
              subtitle="Cheirin de um bom negócio!"
            >
              <Div className="cs-separator cs-accent_bg"></Div>
              <Spacing lg="25" md="0" />
              <p className="cs-m0">
                Trabalhamos essencialmente com cafés especiais da Mantiqueira de
                Minas, região cafeicultora mais premiada e reconhecida em todo
                mundo!
              </p>
            </SectionHeading>
          </Div>
        </Div>
      </Div>
      {/* End Why Choose Section */}

      <Spacing lg="150" md="80" />
      <Div className="container">
        <Div className="row">
          <Div className="col-xl-6 col-lg-6 div-center ">
            <SectionHeading
              title="Cheirin Bão é a marca mais premiada do Brasil
              pela Associação Brasileira De Franchising (ABF) em 2022!"
              subtitle="Cheirin de um bom negócio!"
            >
              <Div className="cs-separator cs-accent_bg"></Div>
              <Spacing lg="25" md="0" />
              <p className="cs-m0">
                No mês de dezembro de 2022, comemoramos o fechamento de um ano
                incrível de crescimento e resultados extraordinários para a
                marca. Uma das grandes conquistas, foi fechar o ano consagrados
                como a marca mais premiada do Brasil pela ABF!
              </p>
            </SectionHeading>
          </Div>
          <Div className="col-xl-5 col-lg-6 offset-xl-1  div-center ">
            <Div className="cs-image_layer cs-style1">
              <Div className="cs-image_layer_in">
                <img
                  loading="lazy"
                  src="/images/bg/ew.jpg"
                  alt="About"
                  className="w-100 cs-radius_15"
                />
              </Div>
            </Div>
          </Div>
        </Div>
      </Div>
      {/* End Especialistas Section */}

      {/* Start CTA Section */}
      <Spacing lg="150" md="80" />
      <Div className="container">
        <Cta
          title="Mais de 50% de nossa rede
          é composta por multifranqueados, ou seja, franqueados que possuem pelo menos duas operações ou mais! "
          subtitle=" Esse é um dos principais pontos que acompanhamos para entender se nossos franqueados estão felizes! Franqueado que está lucrando, adquire a segunda, terceira e quantas franquias o seu sonho alcançar! "
          btnText="Cadastre-se para mais informações"
          btnLink={buttonLink}
          bgSrc="/images/cta_bg.jpeg"
        />
      </Div>
      {/* End CTA Section */}

      <Spacing lg="150" md="80" />
      <Div className="container">
        <Div className="row">
          <Div className="col-xl-5 ">
            <NewsSlider />
          </Div>
          <Div className="col-xl-6 offset-xl-1 div-center ">
            <SectionHeading
              title="Em 2022 alcançamos a marca de 800 franquias
              e entramos em 2023 preparados para surpreender."
              subtitle="Cheirin de um bom negócio!"
            >
              <Div className="cs-separator cs-accent_bg"></Div>
              <Spacing lg="25" md="0" />
              <p className="cs-m0">
                Veja ao lado a matéria que foi feita no portal “O Globo”!
              </p>
            </SectionHeading>
          </Div>
        </Div>
      </Div>

      {/* Start Ambiente Section */}
      <Spacing lg="145" md="80" />
      <Div className="container">
        <SectionHeading
          title="Um pouco das nossas unidades:"
          subtitle="Confira nossos ambientes."
          variant="cs-style1"
        />
        <Spacing lg="20" md="45" />
        <SpaceSlider data={dadosUnidades.data} />
      </Div>
      {/* End Team Section */}

      {/* Start Video Block Section */}
      <Spacing lg="130" md="70" />
      <Div className="container">
        <h2 className="cs-font_50 cs-m0 text-center cs-line_height_4">
          Assista aos depoimentos dos nossos franqueados:
        </h2>
        <Spacing lg="70" md="70" />
        <VideoModal
          videoSrc="https://customer-fv29kr9ah5btqwva.cloudflarestream.com/321f5e16c06122d2bb8b28e1eb5049ee/manifest/video.m3u8"
          poster="https://customer-fv29kr9ah5btqwva.cloudflarestream.com/321f5e16c06122d2bb8b28e1eb5049ee/thumbnails/thumbnail.jpg"
          bgUrl="/images/bg/2.jpg"
        />
      </Div>
      {/* End Video Block Section */}

      <Spacing lg="150" md="80" />
      <Div className="container" id="cadastro">
        <Div className="row">
          <Div className="col-xl-6 col-lg-6 div-center ">
            <SectionHeading
              title="Faturar mais de R$1.2 milhões no ano
              é um sonho que você pode realizar com a Cheirin Bão! ​"
              subtitle="Cheirin de um bom negócio!"
            >
              <Div className="cs-separator cs-accent_bg"></Div>
              <Spacing lg="25" md="0" />
              <p className="cs-m0">
                Aqui você encontra o investimento necessário e mais algumas
                informações sobre o que será incluso ao adquirir uma de nossas
                franquias. Se torne um franqueado da Cheirin Bão agora mesmo,
                clique no botão e baixe nossa apresentação completa.
              </p>
              <Spacing lg="30" md="0" />
              <Div className="desk">
                <ButtonUI
                  btnTarget="_blank"
                  btnText="Cadastre-se para mais informações"
                  backgroundColor="#ffab00"
                  btnTextColor="#000"
                  btnTextHoverColor="#ffab00"
                  backgroundHoverColor="#000"
                  variant="contained"
                  scrollDownId="#service"
                  btnLink={buttonLink}
                ></ButtonUI>
              </Div>
            </SectionHeading>
          </Div>
          <Div className="col-xl-5 col-lg-6 offset-xl-1  div-center ">
            <Div className="cs-image_layer cs-style1">
              <Div className="cs-image_layer_in">
                <img
                  loading="lazy"
                  src="/images/bg/preco5.jpg"
                  alt="About"
                  className="w-100 cs-radius_15"
                />
              </Div>
              <Div className="mob">
                <Spacing lg="30" md="30" />
                <ButtonUI
                  btnTarget="_blank"
                  btnText="Cadastre-se para mais informações"
                  backgroundColor="#ffab00"
                  btnTextColor="#000"
                  btnTextHoverColor="#ffab00"
                  backgroundHoverColor="#000"
                  variant="contained"
                  scrollDownId="#service"
                  btnLink={buttonLink}
                ></ButtonUI>
              </Div>
            </Div>
          </Div>
        </Div>
      </Div>

      {/* Start Awards Section */}
      <Spacing lg="150" md="80" />
      <Div className="cs-shape_wrap_2">
        <Div className="cs-shape_2">
          <Div />
        </Div>
        <Div className="container">
          <Div className="row">
            <Div className="col-xl-4 col-lg-4 div-center ">
              <SectionHeading
                title="Todo suporte por nossa conta!"
                subtitle="Cheirin de um bom negócio!"
              >
                <Div className="cs-separator cs-accent_bg"></Div>
                <Spacing lg="25" md="0" />
                <p className="cs-m0">
                  É nos detalhes que se faz a diferença, e o suporte que a
                  Cheirin Bão dá aos seus franqueados traz muito mais
                  tranquilidade e resultados.
                </p>
              </SectionHeading>
            </Div>
            <Div className="col-xl-7 offset-xl-1">
              <SuporteSlider />
            </Div>
          </Div>
        </Div>
      </Div>
      {/* End Awards Section */}

      {/* Start MovingText Section */}
      <Spacing lg="125" md="70" />
      <MovingText text="A maior rede de cafeterias do Brasil!" />
      <Spacing lg="105" md="70" />
      {/* End MovingText Section */}

      <Div className="container">
        <Cta
          title="Vamos conversar melhor sobre como você ter a sua Cheirin Bão?"
          subtitle="Então baixe nossa apresentação completa e tenha acesso a todos os detalhes da nossa franquia.
          É rápido como tirar um café e pode mudar sua vida. "
          btnText="Cadastre-se para mais informações"
          btnLink={buttonLink}
          bgSrc="/images/cta_bg.jpeg"
        />
      </Div>
      {/* End CTA Section */}
    </>
  );
}
