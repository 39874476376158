import parse from "html-react-parser";
import "./hero.scss";
import ButtonUI from "../Button";
import Div from "../Div";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import {
  MediaCommunitySkin,
  MediaOutlet,
  MediaPlayer,
  MediaPoster,
} from "@vidstack/react";

import "vidstack/styles/defaults.css";
import "vidstack/styles/community-skin/video.css";

export default function Hero({
  title,
  subtitle,
  btnText,
  btnLink,
  bgImageUrl,
  btnVariant,
  btnTextColor,
  btnTextHoverColor,
  backgroundColor,
  backgroundHoverColor,
  scrollDownId,
}) {
  function VideoPlayer() {
    return (
      <MediaPlayer
        src="https://customer-fv29kr9ah5btqwva.cloudflarestream.com/9132ee506e39758de9ec18bdcc90ad8c/manifest/video.m3u8"
        poster={
          process.env.REACT_APP_LINK_IMAGE_RESIZE +
          "/images/thumbs/a-maior-rede.jpg" +
          "&quality=70"
        }
        aspectRatio={16 / 9}
        className="yt-lite"
      >
        <MediaOutlet>
          <MediaPoster />
        </MediaOutlet>
        <MediaCommunitySkin />
      </MediaPlayer>
    );
  }
  return (
    <Div
      className="cs-hero cs-style1 cs-bg cs-fixed_bg cs-shape_wrap_1"
      style={{ backgroundImage: `url(${bgImageUrl})` }}
    >
      <Div className="cs-shape_1" />
      <Div className="cs-shape_1" />
      <Div className="cs-shape_1" />
      <Div className="container">
        <Row>
          <Col xs={6} className="div-center">
            <Div className="cs-hero_text">
              <img
                loading="lazy"
                className="cs-logo-hero"
                src="/images/logos/logo.png"
                alt="Logo"
              />
              <h1 className="cs-hero_title">{parse(title)}</h1>
              <Div className="cs-hero_subtitle">{subtitle}</Div>
              <Div className="mob">
                <Div className="cs-image_layer cs-style1">
                  <Div className="cs-image_layer_in">
                    <VideoPlayer />
                  </Div>
                </Div>
              </Div>
              <Div className="cs-hero_info">
                <ButtonUI
                  btnTarget="_blank"
                  btnLink={btnLink}
                  btnText={btnText}
                  variant={btnVariant}
                  backgroundColor={backgroundColor}
                  btnTextColor={btnTextColor}
                  btnTextHoverColor={btnTextHoverColor}
                  backgroundHoverColor={backgroundHoverColor}
                />
              </Div>
            </Div>{" "}
          </Col>

          <Col xs={6} className="desk">
            <Div className="cs-image_layer cs-style1">
              <Div className="cs-image_layer_in">
                <VideoPlayer />
              </Div>
            </Div>
          </Col>
        </Row>
      </Div>
      <a href={scrollDownId} className="cs-down_btn"></a>
    </Div>
  );
}
