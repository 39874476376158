import React, { useEffect } from "react";
import Div from "../Div";
import SectionHeading from "../SectionHeading";
import Spacing from "../Spacing";
import { pageTitle } from "../../helper";
import Hero8 from "../Hero/Hero8";
import MovingText from "../MovingText";
import Cta from "../CtaTwoButtons";

import "vidstack/styles/defaults.css";
import "vidstack/styles/community-skin/video.css";

import {
  MediaCommunitySkin,
  MediaOutlet,
  MediaPlayer,
  MediaPoster,
} from "@vidstack/react";

export default function Videos() {
  pageTitle("Home");

  function VideoPlayer({ src, poster }) {
    return (
      <MediaPlayer
        src={src}
        poster={poster}
        aspectRatio={16 / 9}
        className="yt-lite"
      >
        <MediaOutlet>
          <MediaPoster />
        </MediaOutlet>
        <MediaCommunitySkin />
      </MediaPlayer>
    );
  }

  useEffect(() => {
    // window.scrollTo(0, 0);
  }, []);

  const buttonLink =
    "https://cb.universalfranchising.com.br/materiais/apresentacao.pdf";
  const buttonLink2 = "https://chat.whatsapp.com/I6ZjvBRu7HZ0tXPKSGyuq7";

  return (
    <>
      {/* Start Hero Section */}
      <Hero8
        title="Conheça um pouco mais sobre a nossa marca"
        subtitle="Separamos alguns videos para que você possa conhecer um pouco mais sobre a nossa marca, a nossa história e sobre os nossos franqueados, acompanhe:"
        btnText="Conhecer a marca"
        btnLink="#next"
        btnTarget="_self"
        btnVariant="contained"
        scrollDownId="#next"
        bgImageUrl="/images/bg/bg-cb-1.jpg"
        backgroundColor="#ffab00"
        btnTextColor="#000"
        btnTextHoverColor="#ffab00"
        backgroundHoverColor="#000"
      />
      {/* End Hero Section */}
      {/* End Especialistas Section */}
      <Spacing lg="200" md="80" />
      <Div className="container" id="next">
        <Div className="row reverse">
          <Div className="col-xl-5 col-lg-6 div-center  ">
            <Div className="cs-image_layer cs-style1">
              <Div className="cs-image_layer_in">
                <VideoPlayer
                  src="https://customer-fv29kr9ah5btqwva.cloudflarestream.com/8a854922a896bacf8f24539d63b5686a/manifest/video.m3u8"
                  poster="https://customer-fv29kr9ah5btqwva.cloudflarestream.com/8a854922a896bacf8f24539d63b5686a/thumbnails/thumbnail.jpg"
                />
              </Div>
            </Div>
          </Div>
          <Div className="col-xl-6 col-lg-6 offset-xl-1 div-center ">
            <SectionHeading
              title="Como ganhar dinheiro com franquias?"
              subtitle="Cheirin de um bom negócio!"
            >
              <Spacing lg="25" md="0" />
              <Div className="cs-separator cs-accent_bg"></Div>
              <Spacing lg="25" md="0" />
              <p className="cs-m0">
                Neste vídeo os sócios e criadores da marca, Wilton e Eduardo
                falam sobre como ganhar dinheiro com franquias.
              </p>
            </SectionHeading>
          </Div>
        </Div>
      </Div>
      {/* End Especialistas Section */}

      <Spacing lg="200" md="80" />
      <Div className="container">
        <Div className="row">
          <Div className="col-xl-6 col-lg-6 div-center ">
            <SectionHeading
              id="faturar"
              title="Conheça um pedacinho da nossa história:"
              subtitle="Cheirin de um bom negócio!"
            >
              <Spacing lg="25" md="0" />
              <Div className="cs-separator cs-accent_bg"></Div>
              <Spacing lg="25" md="0" />
              <p className="cs-m0">
                Neste vídeo você pode acompanhar em números a evolução da nossa
                marca nos últimos anos.
              </p>
            </SectionHeading>
          </Div>
          <Div className="col-xl-5 col-lg-6 offset-xl-1  div-center ">
            <Div className="cs-image_layer cs-style1">
              <Div className="cs-image_layer_in">
                <VideoPlayer
                  src="https://customer-fv29kr9ah5btqwva.cloudflarestream.com/a75706df0206e65199bf57a902538a26/manifest/video.m3u8"
                  poster="https://customer-fv29kr9ah5btqwva.cloudflarestream.com/a75706df0206e65199bf57a902538a26/thumbnails/thumbnail.jpg"
                />
              </Div>
            </Div>
          </Div>
        </Div>
      </Div>
      {/* End Especialistas Section */}

      {/* End Especialistas Section */}
      <Spacing lg="200" md="80" />
      <Div className="container">
        <Div className="row reverse">
          <Div className="col-xl-5 col-lg-6 div-center  ">
            <Div className="cs-image_layer cs-style1">
              <Div className="cs-image_layer_in">
                <VideoPlayer
                  src="https://customer-fv29kr9ah5btqwva.cloudflarestream.com/cb7a4dab74f153b00971ce5e66e00b82/manifest/video.m3u8"
                  poster="https://customer-fv29kr9ah5btqwva.cloudflarestream.com/cb7a4dab74f153b00971ce5e66e00b82/thumbnails/thumbnail.jpg"
                />
              </Div>
            </Div>
          </Div>
          <Div className="col-xl-6 col-lg-6 offset-xl-1 div-center ">
            <SectionHeading
              title="Franquia x Negócio do zero"
              subtitle="Cheirin de um bom negócio!"
            >
              <Spacing lg="25" md="0" />
              <Div className="cs-separator cs-accent_bg"></Div>
              <Spacing lg="25" md="0" />
              <p className="cs-m0">
                Neste vídeo os sócios e criadores da marca, Wilton e Eduardo
                falam sobre a diferença entre criar um negócio do zero e
                adquirir uma franquia. Confira!
              </p>
            </SectionHeading>
          </Div>
        </Div>
      </Div>
      {/* End Especialistas Section */}

      <Spacing lg="200" md="80" />
      <Div className="container">
        <Div className="row">
          <Div className="col-xl-6 col-lg-6 div-center ">
            <SectionHeading
              id="faturar"
              title="É possível faturar R$150 mil reais com uma franquia da Cheirin Bão?"
              subtitle="Cheirin de um bom negócio!"
            >
              <Spacing lg="25" md="0" />
              <Div className="cs-separator cs-accent_bg"></Div>
              <Spacing lg="25" md="0" />
              <p className="cs-m0">
                Neste vídeo, respondendo algumas perguntas, os sócios e
                criadores da marca, Wilton e Eduardo falam se é possível faturar
                R$ 150 mil reais mensais com uma franquia da Cheirin Bão, e
                porque isso é possível.
              </p>
            </SectionHeading>
          </Div>
          <Div className="col-xl-5 col-lg-6 offset-xl-1  div-center ">
            <Div className="cs-image_layer cs-style1">
              <Div className="cs-image_layer_in">
                <VideoPlayer
                  src="https://customer-fv29kr9ah5btqwva.cloudflarestream.com/cb7d8fbda23ac9c5514627adec49ebb3/manifest/video.m3u8"
                  poster="https://customer-fv29kr9ah5btqwva.cloudflarestream.com/cb7d8fbda23ac9c5514627adec49ebb3/thumbnails/thumbnail.jpg"
                />
              </Div>
            </Div>
          </Div>
        </Div>
      </Div>
      {/* End Especialistas Section */}

      {/* End Especialistas Section */}
      <Spacing lg="200" md="80" />
      <Div className="container">
        <Div className="row reverse">
          <Div className="col-xl-5 col-lg-6 div-center  ">
            <Div className="cs-image_layer cs-style1">
              <Div className="cs-image_layer_in">
                <VideoPlayer
                  src="https://customer-fv29kr9ah5btqwva.cloudflarestream.com/b1d62f556d8a67b0dd0a5e25cee54f5d/manifest/video.m3u8"
                  poster="https://customer-fv29kr9ah5btqwva.cloudflarestream.com/b1d62f556d8a67b0dd0a5e25cee54f5d/thumbnails/thumbnail.jpg"
                />
              </Div>
            </Div>
          </Div>
          <Div className="col-xl-6 col-lg-6 offset-xl-1 div-center ">
            <SectionHeading
              title="Veja o relato da Aparecida, nossa franqueada, falando da rentabilidade da sua cafeteria."
              subtitle="Cheirin de um bom negócio!"
            >
              <Spacing lg="25" md="0" />
              <Div className="cs-separator cs-accent_bg"></Div>
              <Spacing lg="25" md="0" />
              <p className="cs-m0">
                Aparecida, nossa franqueada de cidade tal, participou do nosso
                podcast no encontro nacional de franqueados Cheirin Bão em
                novembro de 2022, e durante o bate papo ela contou sobre a
                rentabilidade da sua unidade em comparação a outro negócio de
                sua propriedade, surpreendente a diferença.
              </p>
            </SectionHeading>
          </Div>
        </Div>
      </Div>
      {/* End Especialistas Section */}

      <Spacing lg="200" md="80" />
      <Div className="container">
        <Div className="row">
          <Div className="col-xl-6 col-lg-6 div-center ">
            <SectionHeading
              id="faturar"
              title="Como ter uma sociedade saudável?"
              subtitle="Cheirin de um bom negócio!"
            >
              <Spacing lg="25" md="0" />
              <Div className="cs-separator cs-accent_bg"></Div>
              <Spacing lg="25" md="0" />
              <p className="cs-m0">
                Neste vídeo, os sócios e criadores da marca, Wilton e Eduardo
                falam como é a relação deles como sócios e dão dicas de como ter
                uma sociedade saudável e milionária. Contam todos os erros que
                tiveram em sociedades anteriores e o que aprenderam com tudo
                isso.
              </p>
            </SectionHeading>
          </Div>
          <Div className="col-xl-5 col-lg-6 offset-xl-1  div-center ">
            <Div className="cs-image_layer cs-style1">
              <Div className="cs-image_layer_in">
                <VideoPlayer
                  src="https://customer-fv29kr9ah5btqwva.cloudflarestream.com/7949386d8fe513437174dd81e56dde51/manifest/video.m3u8"
                  poster="/images/thumbs/sociedade_saudavel.jpg"
                />
              </Div>
            </Div>
          </Div>
        </Div>
      </Div>
      {/* End Especialistas Section */}

      {/* End Especialistas Section */}
      <Spacing lg="200" md="80" />
      <Div className="container">
        <Div className="row reverse">
          <Div className="col-xl-5 col-lg-6 div-center  ">
            <Div className="cs-image_layer cs-style1">
              <Div className="cs-image_layer_in">
                <VideoPlayer
                  src="https://customer-fv29kr9ah5btqwva.cloudflarestream.com/4bb8511c1bbeb44677562700bb8d0b35/manifest/video.m3u8"
                  poster="/images/thumbs/leila.jpg"
                />
              </Div>
            </Div>
          </Div>
          <Div className="col-xl-6 col-lg-6 offset-xl-1 div-center ">
            <SectionHeading
              title="Conheça a História da nossa franqueada Leila. Após assumir sua operação triplicou seu faturamento
              "
              subtitle="Cheirin de um bom negócio!"
            >
              <Spacing lg="25" md="0" />
              <Div className="cs-separator cs-accent_bg"></Div>
              <Spacing lg="25" md="0" />
              <p className="cs-m0">
                Franqueada Cheirin Bão há quase cinco anos assumiu uma unidade
                em operação e triplicou o faturamento dentro de um curto
                período.
              </p>
            </SectionHeading>
          </Div>
        </Div>
      </Div>
      {/* End Especialistas Section */}

      <Spacing lg="200" md="80" />
      <Div className="container">
        <Div className="row">
          <Div className="col-xl-6 col-lg-6 div-center ">
            <SectionHeading
              id="faturar"
              title="Franqueados Talita e Kleber saíram do mercado executivo para terem sucesso como multifranqueados Cheirin Bão.
              "
              subtitle="Cheirin de um bom negócio!"
            >
              <Spacing lg="25" md="0" />
              <Div className="cs-separator cs-accent_bg"></Div>
              <Spacing lg="25" md="0" />
              <p className="cs-m0">
                Kleber é um investidor nato, apaixonado por números e desafios,
                enquanto Talita é responsável pela gestão da operação das duas
                unidades, mas uma coisa eles tem em comum: São apaixonados por
                café.
              </p>
            </SectionHeading>
          </Div>
          <Div className="col-xl-5 col-lg-6 offset-xl-1  div-center ">
            <Div className="cs-image_layer cs-style1">
              <Div className="cs-image_layer_in">
                <VideoPlayer
                  src="https://customer-fv29kr9ah5btqwva.cloudflarestream.com/935adbaab79f4cc663dd0317c7228ef5/manifest/video.m3u8"
                  poster="/images/thumbs/tk.jpg"
                />
              </Div>
            </Div>
          </Div>
        </Div>
      </Div>
      {/* End Especialistas Section */}

      <Spacing lg="200" md="80" />
      <Div className="container">
        <Div className="row">
          <Div className="col-xl-6 col-lg-6 div-center ">
            <SectionHeading
              id="faturar"
              title="Convenção Cheirin Bão 2022
              "
              subtitle="Cheirin de um bom negócio!"
            >
              <Spacing lg="25" md="0" />
              <Div className="cs-separator cs-accent_bg"></Div>
              <Spacing lg="25" md="0" />
              <p className="cs-m0">
                Nos dias 17 e 18 de novembro, tivemos a nossa Convenção de
                Franqueados Cheirin Bão 2022. Dentre várias experiências que
                tivemos, uma delas foi o nosso podcast ao vivo, onde
                compartilhamos cases e histórias dos nossos franqueados.
              </p>
            </SectionHeading>
          </Div>
          <Div className="col-xl-5 col-lg-6 offset-xl-1  div-center ">
            <Div className="cs-image_layer cs-style1">
              <Div className="cs-image_layer_in">
                <VideoPlayer
                  src="https://customer-fv29kr9ah5btqwva.cloudflarestream.com/346d14e325d8c2bd4929cbb03488dbaa/manifest/video.m3u8"
                  poster="/images/thumbs/convencao.jpg"
                />
              </Div>
            </Div>
          </Div>
        </Div>
      </Div>
      {/* End Especialistas Section */}

      {/* End Especialistas Section */}
      <Spacing lg="200" md="80" />
      <Div className="container">
        <Div className="row reverse">
          <Div className="col-xl-5 col-lg-6 div-center  ">
            <Div className="cs-image_layer cs-style1">
              <Div className="cs-image_layer_in">
                <VideoPlayer
                  src="https://customer-fv29kr9ah5btqwva.cloudflarestream.com/1961a8be91cf31091d092ba375fafb5e/manifest/video.m3u8"
                  poster="/images/thumbs/marcelos.jpg"
                />
              </Div>
            </Div>
          </Div>
          <Div className="col-xl-6 col-lg-6 offset-xl-1 div-center ">
            <SectionHeading
              title="Angela e Monique - Os desafios enfrentados no empreendedorismo."
              subtitle="Cheirin de um bom negócio!"
            >
              <Spacing lg="25" md="0" />
              <Div className="cs-separator cs-accent_bg"></Div>
              <Spacing lg="25" md="0" />
              <p className="cs-m0">
                Monique é franqueada da unidade de Tatuapé/SP e Angela do
                shopping Tietê Plaza em São Paulo, as duas enfrentaram um grande
                desafio durante a pandemia, que teve seus efeitos de lockdown
                mais severos em São Paulo. Após a pandemia, conseguiram escalar
                bons resultados com um movimento de pessoas agora constante e
                estratégias claras auxiliadas pela franqueadora.
              </p>
            </SectionHeading>
          </Div>
        </Div>
      </Div>
      {/* End Especialistas Section */}



      {/* Start MovingText Section */}
      <Spacing lg="125" md="70" />
      <MovingText text="A maior rede de cafeterias do Brasil!" />
      <Spacing lg="105" md="70" />
      {/* End MovingText Section */}

      <Div className="container">
        <Cta
          title="Um de nossos consultores entrará em contato com você para mais informações!"
          subtitle="Fique atento ao seu email e ao seu WhatsApp, nosso canal de comunicação é feita por ambos os métodos"
          btnText="Baixar apresentação completa"
          btnText2="Acessar grupo do WhatsApp"
          btnLink={buttonLink}
          btnLink2={buttonLink2}
          bgSrc="/images/cta_bg.jpeg"
        />
      </Div>
      {/* End CTA Section */}
    </>
  );
}
