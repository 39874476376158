import React from "react";
import "./unidade.scss";
import Div from "../Div";
import Img from "../WebpImage";

export default function Unidade({
  memberName,
  memberDesignation,
  imagemUnidade,
  imagemUnidadeWebp,
}) {
  return (
    <Div className="cs-team cs-style1">
      <Div className="cs-member_thumb">
        <picture>
          <source
            srcset={
              process.env.REACT_APP_LINK_IMAGE_RESIZE +
              imagemUnidadeWebp +
              "&quality=70&width=900"
            }
            type="image/webp"
            width="900"
            height="auto"
          />
          <source
            srcset={
              process.env.REACT_APP_LINK_IMAGE_RESIZE +
              imagemUnidade +
              "&quality=70&width=900"
            }
            type="image/jpg"
            width="900"
            height="auto"
          />
          <img
            src={
              process.env.REACT_APP_LINK_IMAGE_RESIZE +
              imagemUnidade +
              "&quality=70&width=900"
            }
            alt="Unidade CB"
            width="900"
            height="auto"
          />
        </picture>
        <Div className="cs-member_overlay" />
      </Div>
      <Div className="cs-member_info">
        <h2 className="cs-member_name">{memberName}</h2>
        <Div className="cs-member_designation">{memberDesignation}</Div>
      </Div>
    </Div>
  );
}
