import React from "react";
import { pageTitle } from "../../helper";
import Div from "../Div";
import SectionHeading from "../SectionHeading";
import Spacing from "../Spacing";
import Animation from "../Animations";

import ButtonUI from "../Button";

export default function ErrorPage() {
  pageTitle("Error");

  function goBack() {
    window.history.back();
  }

  return (
    <Div
      className="cs-page_heading cs-style1 cs-center text-center cs-bg cs-error_page"
      style={{ backgroundColor: "#000" }}
    >
      <Div className="container">
        <Animation data="error" style={{ height: 200 }} />
        <SectionHeading
          title="Ops! Página não encontrada!"
          variant="cs-style2 text-center"
        />
        <Spacing lg="30" md="30" />
        <ButtonUI
          btnTarget="_self"
          btnText="Voltar"
          backgroundColor="#ffab00"
          btnTextColor="#000"
          btnTextHoverColor="#ffab00"
          backgroundHoverColor="#000"
          variant="contained"
          onClick={goBack}
        ></ButtonUI>
      </Div>
    </Div>
  );
}
