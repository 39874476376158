import React, { useEffect } from "react";
import { Outlet } from "react-router-dom";
import CustomCursor from "../CustomCursor";
import Footer from "../Footer";

export default function Layout2({ headerVariant }) {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Outlet />
      <CustomCursor />
    </>
  );
}
