import React from "react";
import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";

export default function ButtonUI({
  btnLink,
  btnText,
  variant,
  btnTextColor,
  btnTextHoverColor,
  backgroundColor,
  backgroundHoverColor,
  btnTarget,
  onClick,
}) {
  const BootstrapButton = styled(Button)({
    boxShadow: "-12px 12px 57px -15px rgb(0 0 0 / 50%)",
    textTransform: "none",
    fontSize: 17,
    padding: "15px 50px 15px 50px",
    borderRadius: 15,
    border: "1px solid",
    lineHeight: 1.5,
    borderColor: "#ffab00",
    fontFamily: "Open Sans",
    color: btnTextColor,
    backgroundColor: backgroundColor,
    fontWeight: 700,
    "&:hover": {
      backgroundColor: backgroundHoverColor,
      color: btnTextHoverColor,
      borderColor: "#ffab00",
    },
    "&:active": {
      boxShadow: "none",
      backgroundColor: "#0062cc",
      borderColor: "#005cbf",
    },
    "&:focus": {
      boxShadow: "-12px 12px 57px -15px rgb(0 0 0 / 50%)",
    },
  });

  return (
    <BootstrapButton
      target={btnTarget}
      className="buttonui"
      href={btnLink}
      variant={variant}
      disableRipple
      onClick={onClick}
    >
      {btnText}
    </BootstrapButton>
  );
}
