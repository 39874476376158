import React from "react";
import "./cta.scss";
import ButtonUI from "../Button";
import Div from "../Div";
import Spacing from "../Spacing";

export default function Cta({
  title,
  btnText,
  btnLink,
  bgSrc,
  variant,
  subtitle,
}) {
  return (
    <Div
      className={`cs-cta cs-style1 cs-bg text-center cs-shape_wrap_1 cs-position_1 ${
        variant ? variant : ""
      }`}
      // style={{ backgroundImage: `url(${bgSrc})` }}
    >
      <Div className="cs-shape_1" />
      <Div className="cs-shape_1" />
      <Div className="cs-shape_1" />
      <Div className="cs-cta_in">
        <h3 className="cs-cta_title cs-semi_bold cs-m0">{title}</h3>
        {btnText && (
          <>
            <p className="cs-semi_bold cs-m0">{subtitle}</p>
            <Spacing lg="20" md="30" />
            <ButtonUI
              btnText={btnText}
              backgroundColor="#ffab00"
              btnTextColor="#000"
              btnTextHoverColor="#ffab00"
              backgroundHoverColor="#000"
              variant="contained"
              btnLink={btnLink}
            ></ButtonUI>
          </>
        )}
      </Div>
    </Div>
  );
}
