import React from "react";
import Div from "../Div";
import "./timeline.scss";

import Spacing from "../Spacing";

import Row from "react-bootstrap/Row";

import Col from "react-bootstrap/Col";

export default function TimelinePre({ columnData }) {
  return (
    <>
      {columnData.map((item, index) => (
        <Row key={index}>
          <Div className="cs-time_line cs-style1">
            <Col xs={12} style={{ display: "inline-flex" }}>
              <Col xs={3}>
                <Div>
                  <span className="cs-iconbox_icon cs-center">
                    <img
                      loading="lazy"
                      width="30px"
                      alt="Café"
                      src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADwAAAA8CAYAAAA6/NlyAAAACXBIWXMAAAsTAAALEwEAmpwYAAAA6klEQVR4nO3Y3QqCQBCG4bnDfig8qHsPCirwEgre2NDqoCLL1Z3pe46MDvRtB1czExERERERMTNgBhyAGliE/lGACjhxV9sfxSZH+6PY9Hlu0QDLJ7FnYGXRKBatrH8aYzTG/qGtB+2z7qExRmPsHmOOMTABtsAmHY/4ilflPvcVsB/qxKPHJs3qZr+AImIfRjrrhRQT20r/+r24iawt6j5LhuhiY3NEFx/bZ7Sb2D6i3cX+Eu029pto97FdosPEfhIdLvaDp6VynqAGWulYK9shOl7sm+i4sS1gCuya18vp7QsRERERETHPLp4V+xSGp93CAAAAAElFTkSuQmCC"
                    />
                  </span>
                </Div>
              </Col>
              <Col xs={9}>
                <Div>
                  <h2>{item.name}</h2>
                </Div>
              </Col>
            </Col>
          </Div>
          <Spacing lg="40" md="0" />
        </Row>
      ))}
    </>
  );
}
