import React, { useEffect, useState } from "react";

export default function FranqueadosParceirosForm() {
  function Form() {
    return (
      <html lang="en">
        <head>
          <meta charset="utf-8" />
          <meta name="viewport" content="width=device-width, initial-scale=1" />
          <title>Seja franqueado da maior rede de cafeterias do Brasil!</title>
          {/* <style>*{margin:0;padding:0;} html,body,iframe{border-radius:0 !important;}</style> */}
        </head>
        <body>
          <div
            data-tf-widget="gpTAPN1u"
            data-tf-opacity="100"
            data-tf-inline-on-mobile
            data-tf-iframe-props="title=Seja franqueado da maior rede de cafeterias do Brasil!"
            data-tf-transitive-search-params
            data-tf-auto-focus
            data-tf-medium="snippet"
            data-tf-full-screen
          ></div>
        </body>
      </html>
    );
  }

  useEffect(() => {
    const script = document.createElement("script");

    script.src = "https://embed.typeform.com/next/embed.js";
    script.async = true;

    document.body.appendChild(script);
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Form />
    </>
  );
}
