import parse from "html-react-parser";
import "./hero.scss";
import ButtonUI from "../Button";
import Div from "../Div";
import Row from "react-bootstrap/Row";
import Spacing from "../Spacing";

export default function Hero8({
  title,
  subtitle,
  btnText,
  btnLink,
  bgImageUrl,
  btnVariant,
  btnTextColor,
  btnTextHoverColor,
  backgroundColor,
  backgroundHoverColor,
  scrollDownId,
  btnTarget,
}) {
  return (
    <Div
      className="cs-hero cs-style1 cs-bg cs-shape_wrap_1 cs-type1 text-center cs-fixed_bg"
      style={{ backgroundImage: `url(${bgImageUrl})` }}
    >
      <Div className="cs-shape_1" />
      <Div className="cs-shape_1" />
      <Div className="cs-shape_1" />
      <Div className="container">
        <Row>
          <Div className="cs-image_layer">
            <Div className="cs-image_layer_in">
              <img
                loading="lazy"
                id="logo-top"
                src="/images/logos/logo.png"
                alt="Logo CB"
                className="w-100 cs-radius_15"
              />
            </Div>
          </Div>
          <Spacing lg="30" md="30" />
          <Div className="cs-hero_text">
            <h1 className="cs-hero_title">{parse(title)}</h1>
            <Div className="cs-hero_subtitle">{subtitle}</Div>
            <Div className="cs-cta_in">
              <ButtonUI
                btnTarget={btnTarget}
                btnLink={btnLink}
                btnText={btnText}
                variant={btnVariant}
                backgroundColor={backgroundColor}
                btnTextColor={btnTextColor}
                btnTextHoverColor={btnTextHoverColor}
                backgroundHoverColor={backgroundHoverColor}
              />
            </Div>
          </Div>{" "}
        </Row>
      </Div>
      <a href={scrollDownId} className="cs-down_btn">
        .
      </a>
    </Div>
  );
}
