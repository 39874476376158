import React from "react";
import "./news.scss";
import Div from "../Div";

export default function News({ memberName, memberDesignation, newsImage }) {
  return (
    <Div className="cs-team cs-style1">
      <Div className="cs-member_thumb">
        <picture>
          <source
            srcset={
              process.env.REACT_APP_LINK_IMAGE_RESIZE +
              newsImage +
              "&quality=70&width=900"
            }
            type="image/webp"
          />
          <source
            srcset={
              process.env.REACT_APP_LINK_IMAGE_RESIZE +
              newsImage +
              "&quality=70&width=900"
            }
            type="image/jpg"
          />
          <img
            src={
              process.env.REACT_APP_LINK_IMAGE_RESIZE +
              newsImage +
              "&quality=70&width=900"
            }
            alt="Unidade CB"
          />
        </picture>
        <Div className="cs-member_overlay" />
      </Div>
      <Div className="cs-member_info">
        <h2 className="cs-member_name">{memberName}</h2>
        <Div className="cs-member_designation">{memberDesignation}</Div>
      </Div>
    </Div>
  );
}
