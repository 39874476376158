import React from "react";
import Lottie from "lottie-react";
import error from "./error.json";
import swipe from "./swipe-down.json";

export default function Animation({ style, data }) {
  switch (data) {
    case "error":
      return <Lottie animationData={error} loop={true} style={style} />;
    case "swipe":
      return <Lottie animationData={swipe} loop={true} style={style} />;
    default:
      return null;
  }
}
